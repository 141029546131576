module.exports = {
  defaultTitle: 'Akrom | Personal website',
  logo: 'https://akrom.dev/favicon/logo_dark.png',
  author: 'Akramjon Mamasoliev (Sprinter)',
  url: 'https://akrom.dev',
  shortUrl: 'https://akrom.dev',
  legalName: 'Akramjon Mamasoliev',
  defaultDescription:
    'I’m Akramjon Mamasoliev, AKA Sprinter. I have recently graduated from Sejong University in Seoul majoring in Computer Science and Engineering. During my years at University, I have gained invaluable knowledge in a wide range of topics such as Data Structures, Algorithms, Databases and different programming languages such as Python, C/C++ and Java. But recently, my major focus has been in the field of web development and mobile app development. I have a lot of passion for building softwares and solving technical problems.',
  socialLinks: {
    gitlab: 'https://gitlab.com/AkromDev',
    github: 'https://github.com/AkromDev',
    linkedin: 'https://www.linkedin.com/in/akramjon',
    codesanbox: 'https://codesandbox.io/u/AkromDev',
    email: 'akromdev@gmail.com',
  },
  // googleAnalyticsID: 'UA-88875900-1',
  themeColor: '#00c6ff',
  backgroundColor: '#00c6ff',
  siteRss: '/rss.xml',
  social: {
    // facebook: '165244147328608',
    // twitter: '@smakosh',
  },
  address: {
    city: 'Seoul',
    region: 'Gwangjin-gu',
    country: 'South Korea',
    zipCode: '04999',
  },
  contact: {
    email: 'akromdev@gmail.com',
    // phone: '',
  },
  foundingDate: '2020',
}

import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { ThemeContext } from 'providers/ThemeProvider'
import { Links } from './styles'
import ThemeToggle from 'components/common/ThemeToggle'

export default ({ desktop }) => {
  const { theme } = useContext(ThemeContext)
  return (
      <Links desktop={desktop} theme={theme}>
        <Link to="/" activeClassName="current">
          Home
        </Link>
        <Link to="/about/" activeClassName="current">
          About
        </Link>
        <Link to="/blog/" activeClassName="current">
          Blog
        </Link>
        <Link to="/resume/" activeClassName="current">
          Resume
        </Link>
        {/* <Link to="/talks" activeClassName="current">
          Talks
        </Link> */}
        {/* <Link to="/contact/" activeClassName="current">
          Contact
        </Link> */}
        {desktop && <ThemeToggle />}
      </Links>
  )
}

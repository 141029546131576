import React from 'react'

export const Logo = ({ color, className, strokeWidth }) => (
  //   <svg
  //     className={className}
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 60 60"
  //   >
  //     <path
  //       fill="none"
  //       stroke={color}
  //       strokeWidth={strokeWidth}
  //       d="M52.3 14.8a27 27 0 0 1-37.4 38.1L36 38.6m16.3-23.8L7.8 45.3A27 27 0 0 1 42.4 5.2"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       strokeMiterlimit="10"
  //     />
  //   </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 165.000000 165.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,165.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M916 1221 c-6 -9 -9 -71 -20 -374 -2 -54 -8 -103 -12 -107 -5 -5 -35
-6 -67 -3 -42 4 -57 10 -58 22 -2 27 -7 53 -23 126 -9 38 -16 85 -16 104 0 19
-7 50 -16 70 -16 36 -78 86 -94 76 -5 -3 -7 -27 -4 -53 5 -42 0 -58 -45 -150
-76 -155 -190 -311 -241 -332 -19 -8 -28 -19 -28 -33 1 -12 -3 -37 -7 -54 -8
-37 6 -44 35 -18 15 13 27 13 110 -5 51 -12 98 -19 104 -16 6 2 2 5 -9 5 -11
1 -45 13 -75 27 -53 24 -54 26 -35 41 11 8 40 24 65 35 25 11 52 26 62 34 10
10 18 12 22 5 7 -11 32 -5 56 14 8 6 40 11 69 11 l55 -1 24 -65 c14 -36 25
-76 26 -90 1 -23 -4 -25 -54 -32 -30 -4 -77 -6 -105 -3 -34 3 -22 -2 40 -15
147 -31 184 -23 157 33 -14 30 -42 139 -42 164 0 19 23 32 57 33 19 0 39 -50
42 -104 4 -75 16 -98 48 -94 25 3 33 13 60 66 0 2 36 -4 80 -13 121 -25 143
-28 143 -18 0 5 -45 24 -100 43 -55 18 -100 37 -100 42 0 25 63 139 90 161 16
14 26 28 22 32 -4 5 -2 5 4 2 13 -7 125 52 131 68 5 17 -11 32 -42 39 -26 6
-42 -1 -104 -46 -138 -99 -166 -111 -175 -75 -3 13 -1 39 4 58 11 41 22 180
24 304 l1 90 -27 3 c-14 2 -29 -1 -32 -7z m-266 -277 c0 -16 -16 -19 -25 -4
-8 13 4 32 16 25 5 -4 9 -13 9 -21z m46 -136 c13 -40 20 -76 16 -79 -7 -7
-144 -46 -189 -55 -35 -7 -28 14 20 60 47 45 117 135 117 150 0 22 15 -9 36
-76z m297 -166 c-20 -35 -37 -41 -47 -16 -4 10 3 25 20 41 35 32 51 17 27 -25z"
      />
      <path
        d="M1232 500 c4 -14 87 -42 100 -34 15 9 -1 21 -34 27 -18 3 -41 9 -52
13 -13 5 -17 3 -14 -6z"
      />
      <path d="M558 463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
    </g>
  </svg>
)

import React, { useContext } from 'react'
import { Card } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import { Wrapper, Work, Title } from './styles'

export const Project = ({ title, projects, side, github }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Wrapper>
      <Title theme={theme}>{title}</Title>
      <Work github={github} side={side}>
        {projects.edges.map(({ node }) => (
          <Card key={node.id} {...node} />
        ))}
      </Work>
    </Wrapper>
  )
}

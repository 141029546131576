import React, { useContext } from 'react'
import { SocialShare, PageTitle, Tag } from 'components/common'
import { ThemeContext } from 'providers/ThemeProvider'
import {
  ArticleWrapper,
  Content,
  ArticleDate,
  Tags,
} from './styles'
import Suggested from './suggested'

export * from './comment'

export const Post = ({ html, frontmatter, timeToRead, next, prev }) => {
  console.log('frontmatter', frontmatter)
  const { theme } = useContext(ThemeContext)
  return (
    <ArticleWrapper theme={theme}>
      <PageTitle>{frontmatter.title}</PageTitle>
      <Tags>
        {frontmatter.tags.map((item, i) => (
          <Tag tag={item} link={`/${item}/`} key={i}>
            {item}
          </Tag>
        ))}
      </Tags>
      <ArticleDate>
        <i>{frontmatter.date} -</i>
        <i>{timeToRead} min read</i>
      </ArticleDate>
      <Content dangerouslySetInnerHTML={{ __html: html }} />
      <SocialShare {...frontmatter} />
      <Suggested prev={prev} next={next}/>
    </ArticleWrapper>
  )
}

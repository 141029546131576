import React from 'react'
import { Link } from 'gatsby'
import {SuggestedNav} from './styles'

export default function Suggested({ prev, next }) {
  return (
    <SuggestedNav>
            <ul>
              <li>
                {prev && (
                  <Link
                    to={prev.frontmatter.path}
                    rel="prev"
                    style={{ marginRight: 20 }}
                  >
                    ← {prev.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.frontmatter.path} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
    </SuggestedNav>
  )
}

import React from 'react'

export const Codesanbox = ({
  width = '16',
  height = '16',
  color = '#fff',
  className = '',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    color={color}
    className={className}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 296"
    // style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
  >
    <path
      d="M115.498 261.088v-106.61L23.814 101.73v60.773l41.996 24.347v45.7l49.688 28.54zm23.814.627l50.605-29.151V185.78l42.269-24.495v-60.011l-92.874 53.621v106.82zm80.66-180.887l-48.817-28.289l-42.863 24.872l-43.188-24.897l-49.252 28.667l91.914 52.882l92.206-53.235zM0 222.212V74.495L127.987 0L256 74.182v147.797l-128.016 73.744L0 222.212z"
      fill="#fff"
    />
    <rect x="0" y="0" width="12" height="14" fill="rgba(0, 0, 0, 0)" />
  </svg>
)

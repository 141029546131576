import React from 'react'
import night from 'assets/icons/star-white.svg'
import day from 'assets/icons/day.svg'
import Toggle from './Toggle'
import { useContext } from 'react'
import { ThemeContext } from 'providers/ThemeProvider'

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)
  return (
    <Toggle
      icons={{
        checked: (
          <img
            src={day}
            width="16"
            alt="day"
            height="16"
            role="presentation"
            style={{ pointerEvents: 'none' }}
          />
        ),
        unchecked: (
          <img
            src={night}
            alt="night"
            width="16"
            height="16"
            role="presentation"
            style={{ pointerEvents: 'none' }}
          />
        ),
      }}
      checked={theme === 'dark'}
      onChange={toggleTheme}
    />
  )
}

export default ThemeToggle
